import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { resetToken } from '../Redux/Slices/csrfSlice';
import {setToken} from '../Redux/Slices/csrfSlice';
import {TOKEN_API} from '../constants';

const useGetToken = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    const fetchToken = async() => {
      try{
      console.log("am getting the user token")
      const response = await fetch(TOKEN_API,{credentials:'include'});
      const resJson =  await response.json();
      console.log(resJson);
      if(resJson?.success === 1){
          console.log(resJson?.data?.CSRF_TOKEN);
          // update the slice
          dispatch(setToken(resJson?.data?.CSRF_TOKEN));
      }
      else{
        // console.log("")
          dispatch(resetToken());
      }
  }
  catch(err){
      console.error(err);
  }
    }
    fetchToken();
  },[]);
}

export default useGetToken;