import React, { useEffect,useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import Table from '../Utils/Table';
import { FaSearch } from "react-icons/fa";
import {Link} from 'react-router-dom';

const Stats = () => {
  const StatsData = useLoaderData();
  console.log(StatsData);
  return (
    <>
    <div className="relative">
      <div className="md:hidden sticky top-0 pb-5 z-20 flex flex-row bg-customGray-light items-center gap-4 justify-center p-4">
        <a href="#product" className="btn flex items-center gap-2"> <FaSearch/> Product</a>
        <a href="#vendor" className="btn flex items-center gap-2"> <FaSearch/> Vendor</a>
      </div>
      <div className="grid lg:grid-cols-2">
        <Table
          id="product"
          data={StatsData?.data?.product}
          title="Vulnerability Count By Product"
          placeholder="Search by products"
          rowHeader="Product Name"
        />
        <Table
          id="vendor"
          data={StatsData?.data?.vendor}
          title="Vulnerability Count By Vendor"
          placeholder="Search by vendors"
          rowHeader="Vendor Name"
        />
      </div>
      </div>
    </>
  );
};

export default Stats;