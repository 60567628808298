import React from 'react';

const ProfileShimmer = () => {
  const profileData = {
    data:[1,2,3,4,5,6,7,8,9,10,11,12]
  };
  return (
        <div class=" animate-pulse bg-customGray-light grid grid-cols-1 lg:grid-cols-2 h-full">
          <div class="p-4 h-full text-center flex flex-col items-center">
          <form className="col-span-5 grid grid-cols-6 grid-rows-2 w-full md:p-6 md:text-xl lg:w-4/5">
              <div className="col-span-full grid grid-cols-6 gap-1">
                <div className="bg-white col-span-4 md:col-span-5 px-3 py-5 rounded-md font-semibold placeholder-gray-500 text-black border border-gray-500 focus:outline-none"></div>
                <button type="submit" className="bg-white rounded-lg h-full p-0 col-span-2 md:col-span-1"></button>
              </div>
              {/* <p className="col-span-full form-error m-2 md:text-xl"></p>
              <p className="col-span-full form-error m-2 md:text-xl"></p> */}
            </form>
            
            <div className="bg-gray-100 h-full w-4/5 rounded-md shadow-lg">
              <table className="p-1 h-full w-full">
                <thead className="">
                  <tr>
                  <th className="py-3 px-2 lg:px-6 uppercase"></th>
                  <th className="py-3 px-2 lg:px-6 uppercase"></th>
                  </tr>
                </thead>
                <tbody >
                  {profileData?.data.length > 0 ? (
                    profileData?.data.map((profile,index)=>{
                    const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
                    return(
                      <tr className={`${rowClass} text-center`} key={profile.profile_id} >
                      <td className="text-gray-600 hover:text-black lg:pl-20 h-2">
                     
                      </td>
                        <td>
                        
                        </td>
                      </tr>
                    )
                  })
                  ):
                  (
                    <tr>
                      <td colSpan="3" className="py-3 lg:px-6 text-center text-gray-500 font-semibold uppercase">Create A Profile</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
            <div className="bg-white rounded-md self-start lg:mt-10 justify-self-center flex flex-col gap-4 justify-center items-center p-4 shadow-lg h-4/5 w-4/5 lg:justify-start lg:w-3/4 lg:h-3/4 ">
              <p className="font-bold uppercase font-inter lg:text-2xl"></p>
              <p className="font-inter text-left font-semibold lg:text-xl lg:p-5"></p>
             <p className="font-inter text-left font-semibold lg:text-xl lg:p-5"></p>
             </div>

        </div>


  );
};

export default ProfileShimmer;