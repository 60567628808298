import React from 'react';
import {useParams } from 'react-router-dom';
import useGetProfileSpecificData from '../../../Utils/Hooks/useGetProfileSpecificData';
import Navigation from './Navigation';
import Keywords from './Keywords';
import Products from './Products';
import { useSelector } from 'react-redux';
import Vendors from './Vendors';

const ProfileSpecific = () => {

  const {profileId} = useParams();
  console.log("profileID from profileSpecific :"+ profileId);
  const { profileData, loading, error, fetchProfile } = useGetProfileSpecificData(profileId);
  const csrfToken = useSelector((store) => store.csrf.token);

  if (loading) {
    //shimmer UI
    return <div>LOADING -- SHIMMER UI</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
    {/* <pre>{JSON.stringify(profileData, null, 2)}</pre> */}
    <div className="h-auto w-full">
    <h1 className="font-inter font-semibold p-4 text-4xl text-center uppercase" > { profileData?.data?.profile_name ? profileData?.data?.profile_name.length > 20 ? profileData?.data?.profile_name.substring(0,20) + "..." : profileData?.data?.profile_name : "loading..."}</h1>
    <Navigation csrfToken={csrfToken} profileId={profileId}/>

    {/* h-full w-full placeholder:w-full  */}
    {/* h-full w-full */}
    {/* <div className="grid grid-cols-12 grid-rows-12 lg:grid-rows-1">
    
      <div className="col-span-full row-span-4 lg:col-span-4 ">
        <Keywords csrfToken={csrfToken} profileId={profileId}/>
        <hr className="border-t-2 border-gray-900 mt-2"/>
      </div>
      <div className="col-span-full row-span-4 lg:col-span-4">
        <Products csrfToken={csrfToken} profileId={profileId}/>
        <hr className="border-t-2 border-gray-900 mt-2"/>
      </div>
      <div className="col-span-full row-span-4 lg:col-span-4">
      <Vendors csrfToken={csrfToken} profileId={profileId}/>
      <hr className="border-t-2 border-gray-900 mt-2"/>
      </div>
    </div> */}

    <div className="flex flex-col lg:flex-row">
      <div className="lg:flex-1">
      <Keywords csrfToken={csrfToken} profileId={profileId}/>
      <hr className="border-t-2 border-gray-900 mt-2 lg:hidden"/>
      </div>

      <div className="lg:flex-1">
      <Products csrfToken={csrfToken} profileId={profileId}/>
      <hr className="border-t-2 border-gray-900 mt-2 lg:hidden"/>
      </div>

      <div className="lg:flex-1">
      <Vendors csrfToken={csrfToken} profileId={profileId}/>
      <hr className="border-t-2 border-gray-900 mt-2 lg:hidden"/>
      </div>

    </div>
    </div>
   
    </>
  )
}

export default ProfileSpecific;