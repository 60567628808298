import React from 'react';
import nistLogo from '../../assets/nist_logo.svg';
import mitreBuild from '../../assets/mitre_build.svg';
import cerias from '../../assets/cerias_logo_in_footer.svg';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <div className="h-full grid grid-cols-2 grid-rows-2   lg:grid-cols-4 lg:grid-rows-1 bg-customBlack">
      <div className="col-start-1 col-end-2 lg:col-start-1 lg:col-end-2">
        <div className="flex h-full justify-center items-center pt-4">
          <Link to="https://www.cerias.purdue.edu/">
          <img src={cerias} alt="cerias_logo" className="w-36 items-center justify-center md:w-60 lg:pl-5 cursor-pointer"></img>
          </Link>
        </div>
      </div>
      <div className="col-start-2 col-end-3 lg:col-start-2 lg:col-end-3">
        
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-customWhite font-inter font-medium py-3">Special Thanks To</p>
          <div className="flex w-full justify-evenly items-center">
            <Link to="https://nvd.nist.gov/">
            <img src={nistLogo} alt="nist_logo" className="w-16 md:w-32 cursor-pointer"></img>
            </Link>
            <Link to="https://cve.mitre.org/">
            <img src={mitreBuild} alt="mitrebuild_logo" className="w-16 md:w-32 cursor-pointer"></img>
            </Link>
           
          </div>
        </div>

      </div>

      <div className="text-customWhite col-span-full lg:col-start-3 lg:col-end-5">
        <div className="flex flex-col justify-center h-full p-4 gap-1">
          <p className="text-sm text-center md:text-base">Do you have questions or problems? Email: cassandra@cerias.purdue.edu</p>
          <div className="text-center text-sm uppercase flex justify-center font-semibold my-3 md:text-base">
            <Link to="/main/disclaimer" className="cursor-pointer text-gray-300 hover:text-white">Disclaimer</Link>
            <span className="px-2">|</span>
            <Link to="/main/privacy" className="cursor-pointer text-gray-300 hover:text-white" >privacy</Link>
            <span className="px-2" >|</span>
            <Link to="/main/cookies" className="cursor-pointer text-gray-300 hover:text-white" >cookies</Link>
            <span className="px-2" >|</span>
            <Link to="/main/publickey" className="cursor-pointer text-gray-300 hover:text-white">public key</Link>
          </div>
          <div className="flex justify-center">
          <p className="text-sm">Copyright Purdue University 2000-2024</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;