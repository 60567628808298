import React,{useRef,useState} from 'react';
import {Link} from 'react-router-dom';
import {IoIosClose} from "react-icons/io"; 
import { RESETPASSWORD_API } from '../../../Utils/constants';
import  Modal  from '../../Utils/Modal';

const ResetPasswordModal = ({token,reset_nonce}) => {
    
    const password1 = useRef();
    const password2 = useRef();

    const [formError,setFormError] = useState("");
    const [loading,setLoading] = useState(false);
    const [showModal,setShowModal] = useState(0);

    const resetPassword = async(newPassword,confirmPassword) => {
      setLoading(true);
      try{
        const postData = {
          'token':token,
          'pass1':newPassword,
          'pass2':confirmPassword,
          'reset_nonce':reset_nonce
        }
        console.log("data I AM SENDING: "+ JSON.stringify(postData,null,2));
        const formData = new FormData();
        Object.keys(postData).forEach((key)=>formData.append(key,postData[key]));
        const options={
          'credentials':'include',
          'method':'POST',
          'body':formData
        }
        const response = await fetch(RESETPASSWORD_API,options);
        const jsonResponse = await response.json();
        console.log("RESPONSE FROM SERVER :" + JSON.stringify(jsonResponse,null,2));
        if(jsonResponse?.success === 1) {
          //navigate to login
          //show the success modal -- stating password successfull and navigate to the login page
          setShowModal(1);
        }
        else{
          //show error modal
          setShowModal(2);
        }
      }
      catch(err){
        console.error(err);
      }
      finally{
        setLoading(false);
      }
    }


    const handleSubmit = async(e) =>{
        e.preventDefault();
        const newPassword = password1.current.value;
        const confirmPassword = password2.current.value;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    
        if (newPassword !== confirmPassword) {
          setFormError("Passwords do not match");
          return;
        } 
        if (newPassword.length < 8) {
          setFormError("Minimum of 8 characters required");
          return;
        } 
        if (newPassword.length > 64) {
          setFormError("Maximum of 64 characters allowed");
          return;
        } 
        if (!regex.test(newPassword)) {
          setFormError("Password must contain at least one digit, one uppercase letter, and one lowercase letter");
          return;
        }
        // If no errors, proceed with form submission
        setFormError("");
        // make your POST request here
        await resetPassword(newPassword,confirmPassword);

    }
  
  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center ">
        <div className="bg-white w-4/5 rounded-md flex flex-col lg:w-1/2">
        <Link to="/main" className="self-end"><IoIosClose className="text-5xl hover:text-red"/></Link>
        <hr className="h-1 bg-black/50 border-none "></hr>
        <h2 className="p-3 font-inter font-bold text-center md:text-xl"> Reset Password </h2>
        <p className="h-1/3 pb-3 text-[11px] text-center font-medium font-inter md:text-lg lg:text-sm">Password must be at least 8 characters & include a digit,uppercase,and a lowercase letter.</p>
        <form onSubmit={handleSubmit} className=" w-full h-full flex flex-col justify-center items-center gap-5">
        {/* className="h-full w-full p-3 text-lg rounded-md border border-black md:h-2/3 lg:text-xl" */}
            <input ref={password1} type="password" placeholder="New password" className="p-3 md:w-4/5 bg-slate-50 border border-black text-lg rounded-md placeholder-black placeholder-opacity-75"></input>
            <input ref={password2} type="password" placeholder="Confirm password" className="p-3 md:w-4/5 bg-slate-50 border border-black text-lg rounded-md placeholder-black placeholder-opacity-75"></input>
            {formError && <p className="form-error">{formError}</p>}
            <button disabled={loading}className="w-1/2 btn items-center mb-5 lg:w-1/3" type="submit"> { loading ? 'Validating...':'Reset' } </button>
        </form>
    </div>
    {showModal === 1 && <Modal headingText="Your Password has been reset successfully" secondaryText="Please Login to continue..."/>}
    {showModal === 2 && <Modal headingText="Sorry, there was an error." secondaryText="please contact cassandra@cerias.purdue.edu for assistance."/>}
    </div>
  )
}

export default ResetPasswordModal;