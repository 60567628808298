import React, { useState } from 'react';

const PublicKey = ({ keyText }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(keyText).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset "copied" state after 2 seconds
        });
    };

    return (
        <div className="public-key-container p-4 border rounded">
            <p className="text-gray-700 mb-2">{keyText}</p>
            <button
                onClick={handleCopy}
                className="btn"
            >
                {copied ? 'Copied!' : 'Copy to Clipboard'}
            </button>
        </div>
    );
};

export default PublicKey;