import { useEffect,useState } from 'react';

const useGetProducts = (profileId) => {
    const [productData,setProductData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    console.log(profileId);

    const fetchProducts = async() => {
        try{
        const res = await fetch(`https://dev.cassandra.cerias.purdue.edu/api/profiles/products/${profileId}`,{credentials:'include'});
        const resJson = await res.json();
        console.log(resJson);
        setProductData(resJson?.data?.products);
        }
        catch(error){
            console.error(error);
            setError(error);
        }
        finally{
            setLoading(false);
        }
    }
    useEffect(()=>{ 
        fetchProducts();
    },[]);
    
    //console.log(userCookie);
    // const response = 
  return {productData,loading,error,fetchProducts};
}

export default useGetProducts;