import React, { useRef, useState, useEffect } from 'react'
import useGetToken from '../../../Utils/Hooks/useGetToken';
import useGetProfileData from '../../../Utils/Hooks/useGetProfileData';
import Error from '../../Utils/Error';
import { MdNotes } from "react-icons/md";
import { IoAdd } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { PROFILE_ADD_API } from '../../../Utils/constants';
import { HiPencilSquare } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import ProfileModal from '../../Utils/ProfileModal';
import ProfileShimmer from '../../Utils/ProfileShimmer';
import useUpdateEmail from '../../../Utils/Hooks/useUpdateEmail';
import { PROFILE_EMAILUPDATE_API } from '../../../Utils/constants';
import { MdPlayArrow } from "react-icons/md";

const Profiles = () => {
  //if the user is able to get to this page 
  // then he must be logged in --> get the cookie and store it csrf slice
  useGetToken(); // set the cookie to csrf slice 
  //actual
  const { profileData, loading, error, fetchProfiles } = useGetProfileData(); // get the profile data
  const [validAddProfile, setValidAddProfile] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isAddProfile, setIsAddProfile] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileId, setProfileId] = useState("");

  console.log(profileData);

  //------------------actucal----------------
  const success = profileData?.success;
  //-------------------------------------

  const profileName = useRef();
  const csrfToken = useSelector((store) => store.csrf.token);
  const formRef = useRef();

  useEffect(() => {
    const postData = async () => {
      if (Object.keys(formErrors).length === 0 && isAddProfile) {
        //we got a valid signup form data to make a post request
        try {
          const postData = {
            CSRF: csrfToken,
            profile_name: profileName?.current?.value
          };
          const formData = new FormData();

          Object.keys(postData).forEach(key => {
            formData.append(key, postData[key]);
          });

          const options = {
            'method': 'POST',
            'body': formData,
            'credentials': 'include'
          };
          // console.log(formData);
          for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
          }
          const response = await fetch(PROFILE_ADD_API, options);
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          if (jsonResponse?.success === 1) {
            // on sucessful post
            setValidAddProfile(true);
            // get the profile_id for the newly created profile
            console.log(jsonResponse?.data?.profile_id);
            setProfileId(jsonResponse?.data?.profile_id);
            //again make a fetch req to get the updated list
            await fetchProfiles();
            // Clear the form fields
            setServerError(false);
            setIsModalVisible(true);
            formRef?.current?.reset();

          }
          else {
            setServerError(true)
          }
        }
        catch (err) {
          console.error("ERROR: " + err);
        }
      }
    }
    postData();

  }, [formErrors]);



  const validate = (values) => {
    let errors = {};
    //regex to check whether profile only contains whitespace and aplphanumeric characters
    const regex = /^[a-zA-Z0-9 ]*$/;
    console.log(values.length);

    if (values.length === 0) {
      errors.err = "Profile name cannot be empty"
    }

    if (values.length > 255) {
      errors.err = "maximum of 255 characters allowed";
    }

    else if (!regex.test(values)) {
      errors.err = "Only alphanumeric and space are allowed";
      // "Password must contain at least one digit, one uppercase letter, and one lowercase letter";
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handling form submit");
    //CLIENT SIDE VALIDATION FOR FORM DATA.
    console.log(JSON.stringify(validate(profileName?.current?.value), null, 2));
    setFormErrors(validate(profileName?.current?.value));
    //KEEP TRACK OF CREATE ACCOUNT BUTTON PRESS.
    setIsAddProfile(true);
    // formRef?.current?.reset();
  }

  const handleCheckboxChange = async (id, checked, token) => {
    console.log("id: :" + id);
    console.log("checked :" + checked);
    console.log("token :" + token);
    let response;
    try {
      const PostData = {
        profile_id: id,
        email_me: checked ? 1 : 0,
        CSRF: csrfToken
      }

      console.log(PostData);

      const formData = new FormData();

      Object.keys(PostData).forEach(key => {
        formData.append(key, PostData[key]);
      });

      const Response = await fetch(PROFILE_EMAILUPDATE_API, {
        'method': 'POST',
        'body': formData,
        'credentials': 'include'
      });
      const jsonResponse = await Response.json();
      console.log("am updating the email preference");
      response = jsonResponse?.success;
    }
    catch (error) {
      console.error(error);
    }
    // const response = await updateEmail(id,checked,token);
    // console.log("response I got from useUpdaTE-EMAIL");
    // console.log(response)
    //after updating the email preference again render the component with updated into i.e make a fetch call to display all profiles
    if (response === 1) await fetchProfiles();
  }

  if (loading) {
    //shimmer UI
    return <ProfileShimmer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <div className={`h-full ${isModalVisible ? 'backdrop-blur-sm' : ''} pb-5`}>
      {success === 1 ?
        <>
          <div class="bg-customGray-light h-full w-full flex flex-col justify-center lg:flex-row">
            <div class="p-4 h-auto w-full text-center flex-1 flex flex-col self-center justify-self-center lg:self-start">
              {/* lg:w-4/5 */}
              <form ref={formRef} className="col-span-5 grid grid-cols-6 grid-rows-1 w-full pb-4 md:text-xl" onSubmit={handleSubmit}>
                <div className="col-span-full grid grid-cols-6 gap-1 ">
                  <input className="col-span-4 md:col-span-5 px-3 py-2 rounded-md font-semibold placeholder-gray-500 text-black border border-gray-500 focus:outline-none" placeholder="profile name" type="text" ref={profileName} />
                  <button type="submit" className="btn p-0 col-span-2 md:col-span-1">Add</button>
                </div>
                {Object.keys(formErrors).length !== 0 && <p className="col-span-full form-error m-2 md:text-xl">{formErrors.err}</p>}
                {serverError && <p className="col-span-full form-error m-2 md:text-xl"> Failed to add profile </p>}
              </form>
              <div className="bg-gray-100 h-auto w-full rounded-md shadow-lg">
                <table className="p-1 h-full w-full">
                  <thead className="">
                    <tr>
                      <th className="py-3 px-2 lg:px-6 uppercase">Profiles</th>
                      <th className="py-3 px-2 lg:px-6 uppercase">Email Me</th>
                    </tr>
                  </thead>
                  <tbody >
                    {profileData?.data.length > 0 ? (
                      profileData?.data.map((profile, index) => {
                        const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
                        return (
                          <tr className={`${rowClass} text-center`} key={profile.profile_id} >
                            <td className="text-gray-600 hover:text-black lg:pl-20">

                              <Link to={`${profile.profile_id}`} className="flex gap-3 p-2 font-semibold font-inter">
                                <HiPencilSquare className="text-2xl" />
                                {/* {profile?.name.length > 10 ? profile?.name.substring(0,10)+"..." : profile?.name} */}
                                <p title={profile?.name} className="w-48 whitespace-normal break-words"> {profile?.name}</p>
                              </Link>

                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="h-5 w-5 rounded-md"
                                checked={profile.send_email === 'N' ? false : true}
                                onChange={e => handleCheckboxChange(profile.profile_id, e.target.checked, csrfToken)}
                              />
                            </td>
                          </tr>
                        )
                      })
                    ) :
                      (
                        <tr>
                          <td colSpan="3" className="bg-white py-3 lg:px-6 text-center text-gray-500 font-semibold uppercase">Create A Profile</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-100 rounded-md flex-1 self-center flex flex-col gap-4 p-4 shadow-lg  h-4/5 w-4/5 lg:m-5 lg:w-3/4 lg:h-auto lg:min-h-[30vh] lg:self-start">
              <h2 className=" border-b border-gray-900 pb-[7px] font-inter font-bold uppercase text-2xl self-center">How to use</h2>
              <div className="flex flex-col h-full justify-evenly gap-5 lg:gap-10">
                <div className="flex  gap-2">
                  <div className="flex items-center w-6">
                    <MdPlayArrow className="text-xl" />
                  </div>
                  <p className="font-semibold text-xl  italic text-left ">
                    Add a profile then add a list of products, vendors, or keywords to the profile.
                  </p>
                </div>
                <div className="flex  gap-2">
                  <div className="flex items-center w-6">
                    <MdPlayArrow className="text-xl" />
                  </div>
                  <p className="font-semibold text-xl  italic text-left max-w-full overflow-wrap break-word">
                    If you would like to receive email notifications, check the 'email me' box.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ProfileModal profileId={profileId} isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </>
        :
        <>
          <Error />
        </>
      }
    </div>
  )

}

export default Profiles;