import React from 'react'
import {Link} from 'react-router-dom';
import { TiUserDelete } from "react-icons/ti";
import { IoDuplicateSharp } from "react-icons/io5";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { PROFILE_DELETE_API,PROFILE_DUPLICATE_API } from '../../../Utils/constants';

const Navigation = ( {profileId,csrfToken} ) => {
    const navigate = useNavigate();

    const handleProfileDeletion = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this profile?");
        if (confirmDelete) {
            try {
                const postData = {
                    profile_id:profileId,
                    CSRF:csrfToken
                }
                const formData = new FormData();
                Object.keys(postData).forEach((key)=>formData.append(key,postData[key]));
                const response = await fetch(PROFILE_DELETE_API, {
                    'method': 'POST',
                    'credentials':'include',
                    'body':formData  
                });
                if (response.ok) {
                    alert('Profile deleted successfully.');
                    navigate('/main/profiles');
                } else {
                    alert('Failed to delete the profile.');
                }
            } catch (error) {
                console.error('Error deleting profile:', error);
                alert('An error occurred while deleting the profile.');
            }
        }
    };

    const handleProfileDuplication = async () => {
        const confirmDuplication = window.confirm("Are you sure you want to duplicate this profile?");
        if (confirmDuplication) {
            try {
                const postData = {
                    profile_id:profileId,
                    CSRF:csrfToken
                }
                const formData = new FormData();
                Object.keys(postData).forEach((key)=>formData.append(key,postData[key])); 
                const response = await fetch(PROFILE_DUPLICATE_API, {
                    'method': 'POST',
                    'credentials':'include',
                    'body':formData
                });
                if (response.ok) {
                    alert('Profile duplicated successfully.');
                    navigate('/main/profiles');
                } else {
                    alert('Failed to duplicate the profile.');
                }
            } catch (error) {
                console.error('Error deleting profile:', error);
                alert('An error occurred while duplicating the profile.');
            }
        }
    };

  return (
    <div aria-label='profile-navigation' className="bg-gray-100 p-3 flex flex-col items-center justify-start md:flex-row md:justify-evenly font-semibold font-inter">
        <button className="flex gap-2 items-center cursor-pointer text-xl hover-zoom " onClick={handleProfileDeletion}> <TiUserDelete className="text-xl "/> Delete Profile </button>
        <button className="flex gap-2 items-center cursor-pointer text-xl hover-zoom" onClick={handleProfileDuplication}> <IoDuplicateSharp className="text-xl"/> Duplicate Profile </button>
        <Link className="flex gap-2 items-center cursor-pointer text-xl hover-zoom" to=""> <IoSearchSharp className="text-xl"/> Search </Link>
    </div>
  )
}

export default Navigation;