
// export const NEWUSER_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/user/new";
// export const STATS_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/stats";
// export const LOGIN_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/user/login";
// export const ISLOGGEDIN_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/user/isloggedin";
// export const LOGOUT_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/user/logout";
// export const TOKEN_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/user/csrf";
// export const PROFILE_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/profiles";
// export const PROFILE_ADD_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/profiles/addprofile";
// export const PROFILE_EMAILUPDATE_API = "https://localdev.cassandra.cerias.purdue.edu:8443/api/profiles/email_me";


export const PUBLIC_KEY = `mQGiBEZ7zI4RBACiFIT2HWRy7vnF4xGjGsZocLvbAdKdVmZK5xsG0ulAOr8+njJ1
7XbnC9NAauWp7BOdrEnGm/5NNYksSVTI10WqcMVq63mVZtTwftKzvPjBx0/mqri3
+hb8hVa3q/WCr+JOeMMAuAWGtFIXXAA0w8X1G2a9tN45Z7GTYooy+FXWPwCgp5BC
dSQ98n9XCaYjKgmUi/ylpFsD/iJG9mPECG2HS3R//Qb/qLDupBdczTWzpEFqKH2b
F5EpB79zh55XfWg+tKy9CxPreIYUxWXvWVk/nb8XLVLhiUiDhSGynYzNEKxnqaQt
MxR2js59ZeU0cyMp7TLeU3ryLorJUrPr9q1LGR15CogcL8pYB9zh6oR1t/JYHwGF
lDHgA/9fZNlKcK5DJzEK1lGvh7r3jKf5LutxlCDJ30sehfnRf5MlbWxBexr8D219
/Ln6YkT9ksWQkZgB4hWarjdv++0OVFYFqYW68HPXn70p9rO5tYmQwxdk57AfxDXe
Xj0R8UrW93y3UuWk0tgMqzzAxfNm8p8jmh1P7dSeviSmbzhi/LRpQ2Fzc2FuZHJh
LCBQdXJkdWUgVW5pdmVyc2l0eSBDRVJJQVMgKEZvciBzaWduYXR1cmUgb2Ygb3V0
Z29pbmcgZW1haWxzIG9ubHkpIDxjYXNzYW5kcmFAY2VyaWFzLnB1cmR1ZS5lZHU+
iF8EExECAB8FAkZ7zI4CGwMGCwkIBwMCBBUCCAMDFgIBAh4BAheAAAoJECZlnqG2
WTOp2UkAnifanHAm+idZj6K9Lw7aPLZS1mc7AKCd+qbLwXA7VtwesSUGREhM0Z2Y
WbkEDQRGe8yOEBAAuwkw6xKQv+lD734o3IJ+hwsda1PT5sLhtaxewbWQyvj73a1q
Ws3NWDM93dqlqGVk+FokECbdn4wfkIjQIzNHC6245RR/s4AH0M+cK+xICLOo8Q5k
5u0LzPnIgxiFN91S3bTNyAjYMmXPLtpB4l6xzkZWsdPusayRtnuSMw/ulutbarSJ
cC90BM5Cwd52rLDD+wWdsUnyv/rcPWoV1JH+SNKYJQgStnNTeHlNZnLHTY98A7BI
xZNU2QWk8kmZeUxCrLBIU+i5VLGn9UwsNZ841m0cHAP8DQe16VNrBXw2t0RLNz49
WrmSzf92OrMW2GjwmG5Eu1+Hdee/LywIfCtg/IOgku0sq1cpEQ7/aGtbGqX7U1qU
l5q0IkiraCB8E1fHvegyWD5pFfYRdQf+mgJYqu0zK1s9oqFNOnBVpJQ/9zJZJWnP
1bzkte93EFFHRl1Qhn7hz0Chpa4T0gu+NWIz/An5dr/O+XTwikItEejudidqoioE
C3yGgUNxHRERqyUiFxvhQvpqJdrFHKZ6F3rEl6+CYOZZ+qwhFy4JZ1nidChfmVWQ
1OSLZliFYUgbY2SgJEQAQbquIzO24l2rdUk4tHnpjwnPJP54KQgvloR2nSnn1Mzs
S//fzt6uuWvu2suyCdorpMkTND1kiy5fjyqb9LLeixcoIPw/ho1FtdhrPF8AAwcP
/RzraCw5lu1G0Cwjzk0ebmdXVMVK4CDpzrBWMaqo2Ap2EpLVWohCWTHFIFyh4n1H
13M8IUek1ItXLFrZNHNwmabwu7NCglyaIHGu5fW70S7Wj/yCpdydB6Zim95RwJNF
MGE1gqtKSd2CcvQMdU1gRoKtnKJhEcvqOcQTEzxYV4nXQEf2bbzZgP4onoSHQ5o+
MiSGS8pzGowv7VJSfdcWWqDdK0HHLihT7eUYHZngrfzk5Py99AtjAQ5+CF8pKAO6
7cMD3/r4QI0ozGGk3ndGpEuUqkFRn4jQ2XssDK8grPGf4ypgDbERIDfxfhW+WAi1
wYDdvkaw+ZzYclWgHf+s8LMSamuz25uod37g/wo1yqx3Iu815IemmqwG6euF7QHR
e3yMr4de1aRsGcs/Apniob8VS9C6+lzg1nUy6giY2hFBJdIiUDUquoHG7w9tvdE5
E/GPw3CY6AGH48HtoGwnmWOzvRuiTkCBVXytY2crPbfDmzIGj0Zykp6CWJhgauWv
HiiIbKwp3Ox1cbo9u9cbSSXufbGLr0S5ISm3ZmtRBYpkcVps0ZvUX2Pi3NiYJXIa
JDUKQGJr7Jm6jwIavQeLpSWv7sQCfEBYZND0UAUpZuV8DsUzNDYGtjCVbDHOL9aN
eynpmg9LLIV+9fETy9VIEvhYkXH++lntHAEbCs7kKT8iiEkEGBECAAkFAkZ7zI4C
GwwACgkQJmWeobZZM6lMgQCgi/FCDThqzlb+D0crOA0zjAKNIhQAniwhhKb+Oh76
KZxloUWwXE10Whce
=CZGJ`;

export const NEWUSER_API = "https://dev.cassandra.cerias.purdue.edu/api/user/new";
export const LOGIN_API = "https://dev.cassandra.cerias.purdue.edu/api/user/login";
export const ISLOGGEDIN_API = "https://dev.cassandra.cerias.purdue.edu/api/user/isloggedin";
export const LOGOUT_API = "https://dev.cassandra.cerias.purdue.edu/api/user/logout";
export const TOKEN_API = "https://dev.cassandra.cerias.purdue.edu/api/user/csrf";

export const FORGOTPASSWORD_API = "https://dev.cassandra.cerias.purdue.edu/api/user/forgot_password";
export const RESETPASSWORD_API = "https://dev.cassandra.cerias.purdue.edu/api/user/reset_password";

export const STATS_API = "https://dev.cassandra.cerias.purdue.edu/api/stats";

export const PROFILE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles";
export const PROFILE_ADD_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/addprofile";
export const PROFILE_EMAILUPDATE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/email_me";
export const PROFILE_DELETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/delete";
export const PROFILE_DUPLICATE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/duplicate";

export const KEYWORD_ADD_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/addkeyword";
export const KEYWORD_DELETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/deletekeyword";

export const PRODUCT_ADD_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/addproduct";
export const PRODUCT_DELETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/deleteproduct";
export const PRODUCT_AUTOCOMPLETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/get_products";

export const VENDOR_ADD_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/addvendor";
export const VENDOR_DELETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/deletevendor";
export const VENDOR_AUTOCOMPLETE_API = "https://dev.cassandra.cerias.purdue.edu/api/profiles/get_vendors";

 