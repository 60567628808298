import React from 'react';
import {Link} from 'react-router-dom';
import {IoIosClose} from "react-icons/io"; 
const Modal = ({headingText,secondaryText}) => {
  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center ">
        <div className="bg-white w-4/5 grid grid-rows-4 rounded-lg md:text-lg lg:text-xl lg:w-2/5 ">
        <Link to="/main" className="justify-self-end self-center"><IoIosClose className="text-5xl hover:text-red"/></Link>
        <div className="">
        <hr className="h-1 bg-black/50 border-none "></hr>
        <h1 className="p-3 font-inter font-bold text-center"> {headingText} </h1>
        </div>
        <h3 className="p-3 font-inter text-center font-semibold italic"> {secondaryText} </h3>
        <div className="justify-self-center self-center pb-6"><Link to="/main" className="btn"> continue </Link></div>
        </div>
    </div>
  )
}

export default Modal;