import React, { createContext } from "react";
import useIsLoggedIn from "../../Utils/Hooks/useIsLoggedIn"; // Adjust the path as necessary

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, loading } = useIsLoggedIn();

  // If still loading, you might want to return a loading spinner or some placeholder UI
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
