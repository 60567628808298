import React from 'react';
import {Link} from 'react-router-dom';

const About = () => {
  return (
    <div className="flex flex-col items-center gap-4 p-5">
      <h1 className="heading "> The Cassandra Tool </h1>
      <div className="bg-gray-100 p-4 md:w-4/5 md:p-10  shadow-lg rounded-md">
        <p className="pb-5 lg:text-xl">
        This tool simplifies keeping up-to-date with vulnerabilities in the <Link to="https://nvd.nist.gov/" className="hyperlinks"> National Vulnerability Database (NVD, formerly ICAT)  </Link> or<Link to="https://www.flexera.com/products/security/software-vulnerability-research/secunia-research?referrer=secunia" className="hyperlinks"> Secunia databases. </Link> 
        Instead of going to these sites every day and repeating your searches, Cassandra does the work for you (even twice a day for Secunia). 
        It works by saving lists of products, vendors and keywords into "profiles". 
        </p>
        <p className="lg:text-xl">
        Whenever new information is available, Cassandra can notify you by email. 
        You can create as many profiles as you want, for networks, typical installs, important hosts, or simply areas of interest to you. 
        The important thing is that you should get emails only for things that are relevant to you, so you don't have to scan every message on various mailing lists.
        Please see my blog entry on new features in Cassandra, and some notes on the limitations of the service.
        </p>
      </div>
    </div>
  )
}

export default About;