import React, { useRef, useState, useEffect } from 'react'

import useGetProducts from '../../../Utils/Hooks/useGetProducts';
import AutoComplete from './AutoComplete';
import useDebounce from '../../../Utils/Hooks/useDebounce';
import { PRODUCT_ADD_API, PRODUCT_AUTOCOMPLETE_API, PRODUCT_DELETE_API } from '../../../Utils/constants';
const Products = ({ csrfToken, profileId }) => {
  const formRef = useRef();
  const { productData, loading, error, fetchProducts } = useGetProducts(profileId);
  const [serverError, setServerError] = useState(false);
  const [formError, setFormError] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handling form submit");
    console.log("This Is the Option I Selected!!!!!!!!!!!!!: " + JSON.stringify(selectedOption, null, 2));
    //CLIENT SIDE VALIDATION FOR FORM DATA.
    // you cannot post empty form
    if (!selectedOption) {
      setFormError("Please select a product - vendor.");
      return;
    }
    // post the data
    const postProduct = async () => {
      try {
        const postData = {
          profile_id: profileId,
          product_id: selectedOption?.product_id,
          vendor_id: selectedOption?.vendor_id,
          CSRF: csrfToken
        }
        const formData = new FormData();
        Object.keys(postData).forEach((key) => formData.append(key, postData[key]));
        const options = {
          'method': 'POST',
          'credentials': 'include',
          'body': formData
        }
        const Response = await fetch(PRODUCT_ADD_API, options);
        const jsonResponse = await Response.json();
        // on successful post
        if (jsonResponse?.success === 1) {
          //fetch the products again
          await fetchProducts();
          formRef?.current?.reset();
          setSelectedOption(null);
          setServerError(false);
          setFormError("");
        }
        else {
          setServerError(true);
        }
      }
      catch (err) {
        console.error(err);
      }
    }
    await postProduct();
    setSelectedOption(null);
  }

  const handleProductDeletion = async (map_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Product?");
    if (confirmDelete) {
      try {
        const postData = {
          profile_id: profileId,
          CSRF: csrfToken,
          map_id: map_id
        }
        const formData = new FormData();
        Object.keys(postData).forEach((key) => formData.append(key, postData[key]));

        const options = {
          'method': 'POST',
          'credentials': 'include',
          'body': formData
        }
        const response = await fetch(PRODUCT_DELETE_API, options);
        const jsonResponse = await response.json();
        if (jsonResponse?.success === 1) {
          alert('Product deleted successfully.');
          //navigate('/profiles');
          //again fetch the keyword list
          await fetchProducts();
        } else {
          alert('Failed to delete the Product.');
        }
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('An error occurred while deleting the product.');
      }
    }
  }

  const fetchSimilarProducts = async (query) => {
    setOptionsLoading(true);
    try {
      const formData = new FormData();
      formData.append('keyword', query);

      const options = {
        'method': 'POST',
        'body': formData,
        'credentials': 'include'
      }

      const response = await fetch(PRODUCT_AUTOCOMPLETE_API, options);
      const jsonResponse = await response.json();
      if (jsonResponse?.success === 1) {
        setOptions(jsonResponse?.data?.results);
      }
      else {
        setOptions([]);
      }
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setOptionsLoading(false);
    }
  }
  const debouncedFetch = useDebounce(fetchSimilarProducts, 300);

  // for the loading product component -- on initial render -- and filling the existing products
  if (loading) {
    //shimmer UI
    return <div>LOADING... -- SHIMMER-UI--</div>
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <>
      {/* <pre className="w-full overflow-auto">{JSON.stringify(productData, null, 2)}</pre> */}

      <div class="p-2 h-auto w-full text-center flex flex-col items-center">
        <h2 className="font-inter text-xl font-semibold p-4 uppercase underline underline-offset-2"> Products </h2>
        {/* <form ref={formRef} className="col-span-5 grid grid-cols-6 grid-rows-1 w-full md:p-6 md:text-xl lg:p-0" onSubmit={handleSubmit}>
          <div className="col-span-full grid grid-cols-6 gap-2">
            <div className=" col-span-4 md:col-span-5 text-[20px] self-center justify-self-center lg:w-full h-4/5">
            <AutoComplete
              options={options}
              // setInputValue={setInputValue}
              debouncedFetch={debouncedFetch}
              loading={optionsLoading}
              placeholder="Add products - (vendor)"
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              vendorOrProduct={true}
            />
            </div>

            <button type="submit" className="btn p-0 h-[45px] col-span-2 text-[20px] place-self-center md:col-span-1">Add</button>

          </div>
          {Object.keys(formError).length!==0 && <p className="col-span-full form-error m-2 md:text-xl">{formError}</p>}
          {serverError && <p className="col-span-full form-error m-2 md:text-xl"> Failed to add product </p>}
        </form> */}
        <form ref={formRef} className="col-span-5 grid grid-cols-6 grid-rows-1 w-full md:p-6 md:text-xl lg:p-0" onSubmit={handleSubmit}>
  <div className="col-span-full grid grid-cols-6 gap-2 mb-4">
    <div className="col-span-4 md:col-span-5 text-[20px] lg:w-full h-4/5">
      <AutoComplete
        options={options}
        debouncedFetch={debouncedFetch}
        loading={optionsLoading}
        placeholder="Add Products - (Vendor)"
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        vendorOrProduct={true}
      />
    </div>
    <div className="col-span-2 md:col-span-1 flex ">
      <button type="submit" className="btn p-0 h-4/5 w-full text-[20px] place-self-center">Add</button>
    </div>
  </div>
  {Object.keys(formError).length !== 0 && <p className="col-span-full form-error m-2 md:text-xl">{formError}</p>}
  {serverError && <p className="col-span-full form-error m-2 md:text-xl">Failed to add product</p>}
</form>
        <div className="h-full w-full rounded-md  justify-items-center items-center md:p-6 lg:w-full lg:m-2 lg:p-0">
          <table className="h-full w-full shadow-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-2 text-[20px] lg:px-6 uppercase text-center">Products</th>
                <th className="py-3 px-2 text-[20px] lg:px-6 uppercase text-center">Vendor</th>
                <th className="py-3 px-2 text-[20px] lg:px-6 uppercase text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {productData.length > 0 ? (
                productData.map((product, index) => {
                  const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
                  const btnBgClass = index % 2 === 1 ? 'bg-white' : 'bg-gray-100';
                  return (
                    <tr className={`${rowClass}`} key={product?.product_id}>
                      <td className="text-gray-600 text-[20px] hover:text-black py-2 px-2">
                        <p className="font-semibold" title={product?.product_name}>
                          {product?.product_name.length > 15 ? product?.product_name.substring(0, 15) + "..." : product?.product_name}
                        </p>
                      </td>
                      <td className="text-gray-600 text-[20px] hover:text-black py-2 px-2">
                        <p className="font-semibold" title={product?.vendor_name}>
                          {product?.vendor_name ? (product?.vendor_name.length > 15 ? product?.vendor_name.substring(0, 15) + "..." : product?.vendor_name) : "any"}
                        </p>
                      </td>
                      <td className="text-center py-3 px-2">
                        <button onClick={() => handleProductDeletion(product?.map_id)} className="text-[17px] btn px-4 py-1 bg-gray-200 rounded"> delete </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" className=" bg-white py-3 lg:px-6 text-center text-gray-500 font-semibold uppercase text-[20px]">Add a Product</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Products;