import React from 'react';

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full bg-white text-black">
      <h1 className="text-6xl font-bold mb-4">500</h1>
      <p className="text-2xl font-semibold mb-4">Internal Server Error</p>
      <p className="text-lg mb-8">Something went wrong. Please try again later.</p>
    </div>
  );
};

export default Error;