import { logOut } from '../Utils/Redux/Slices/userSlice';
import { resetToken } from '../Utils/Redux/Slices/csrfSlice';
import { LOGOUT_API } from '../Utils/constants';
import { Navigate } from 'react-router-dom';

const Logout = async (dispatch) => {
    try {
        const response = await fetch( LOGOUT_API,{credentials:'include'});
        const json = await response.json();
        console.log(json);
        dispatch(logOut());
        dispatch(resetToken());
    }
    catch (err) {
        console.error(err);
    }

}

export default Logout;