import { useEffect,useState} from 'react';
import {ISLOGGEDIN_API} from '../constants';
import { useDispatch } from 'react-redux';
import { logIn } from '../Redux/Slices/userSlice';
import { useNavigate } from 'react-router-dom';


const useIsLoggedIn = () => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=>{
        fetchStatus();
    },[]);

    const fetchStatus = async()=>{
        try{
        setLoading(true);
        const response = await fetch(ISLOGGEDIN_API,{credentials:'include'});
        const responseJson = await response.json();
     
        console.log(responseJson);
        console.log("USER STATUS FETCH!"+ responseJson?.success);
        if(responseJson?.success === 1){
            console.log("am logged in -- so i am navigating to the profiles page");
            dispatch(logIn(true));
            navigate("/main/profiles");   
        } 
        else{
            console.log("not logged IN");
            dispatch(logIn(false));
        } 
        }
        catch(err){
            console.error(err);
            dispatch(logIn(false));
        }
        finally{
            setLoading(false);
        }  
    }
    return loading;
}

export default useIsLoggedIn;

// import { useEffect, useState } from 'react';
// import { ISLOGGEDIN_API } from '../constants';

// const useIsLoggedIn = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetchStatus();
//   }, []);

//   const fetchStatus = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(ISLOGGEDIN_API, { credentials: 'include' });
//       const responseJson = await response.json();

//       if (responseJson?.success === 1) {
//         setIsAuthenticated(true);
//         console.log("User is logged in");
//       } else {
//         setIsAuthenticated(false);
//         console.log("User is not logged in");
//       }
//     } catch (err) {
//       console.error("Error checking login status:", err);
//       setIsAuthenticated(false); // Assume not logged in on error
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { isAuthenticated, loading };
// };

// export default useIsLoggedIn;

