import { configureStore } from "@reduxjs/toolkit";
import userReducer from'./Slices/userSlice';
import csrfReducer from './Slices/csrfSlice';
const appStore = configureStore({
    reducer:{
        user: userReducer,
        csrf: csrfReducer
    },
});

export default appStore;