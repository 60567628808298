import React,{useState} from 'react';
import { FaSearch } from "react-icons/fa";

const Table = ({data,title,placeholder,rowHeader,id}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [statsData, setStatsData] = useState(data);

  const filterProduct = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (e.target.value === '') {
      setStatsData(data);
    }
    else {
      const filteredData = data.filter((entry) => entry.name.toLowerCase().startsWith(query.toLowerCase()));
    //   console.log(FilteredProduct);
      setStatsData(filteredData);
    }
  }

  return (
   
    <div id={id} className="flex flex-col items-center pt-4 md:pt-0 gap-2 md:w-full lg:gap-4 pb-4">
    <h2 className="text-center font-inter underline underline-offset-3 text-xl font-medium py-3">{title}</h2>
    <div className="grid grid-cols-5 w-4/5 md:w-3/5">
      <div className="col-span-1 flex justify-center items-center bg-gray-100">
        <FaSearch className="text-gray-400" />
      </div>
      <div className="col-span-4 md:w-full">
        <input
          type="text"
          placeholder={placeholder}
          value={searchQuery}
          onChange={filterProduct}
          className="px-3 py-2 font-semibold placeholder-gray-500 text-black border border-gray-500 w-full md:w-full focus:no-underline"
        />
      </div>
    </div>
    <div className="w-4/5 md:w-3/5">
      <table className="w-full rounded-md shadow-lg">
        <thead className="bg-gray-50 border-b-2 border-gray-200">
          <tr >
            <th className="py-3 px-2 lg:px-6 text-left">Rank</th>
            <th className="py-3 px-2 lg:px-6 text-left">{rowHeader}</th>
            <th className="py-3 px-2 lg:px-6 text-center md:text-left">Count</th>
          </tr>
        </thead>
        <tbody>
          {statsData.length > 0 ? (
            statsData.map((productInfo, index) => {
              const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
              return (
                <tr key={index} className={rowClass}>
                  <td className="py-3 lg:px-6 text-center lg:text-left">{index + 1}</td>
                  <td className="py-3 lg:px-6 text-left">{productInfo?.name}</td>
                  <td className="py-3 lg:px-6 text-left">{productInfo?.c}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="3" className="py-3 lg:px-6 text-center text-gray-500 font-semibold">No Data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default Table;