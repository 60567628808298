import React, { useEffect, useState } from 'react';
import { FORGOTPASSWORD_API } from '../../../Utils/constants';

const ForgotPassword = () => {

  const initalEmailMessage = { email: "" };
  const [formData, setFormData] = useState(initalEmailMessage);
  const [isReset, setIsReset] = useState(false);
  const [emailError, setEmailError] = useState({});
  const [validReset, setValidReset] = useState(false);
  const [loading,setLoading] = useState(false);

  // useEffect(async() => {
  //   console.log("emailError: " + formData.email + "Length: " + formData.email.length);
  //   if (Object.keys(emailError).length === 0 && isReset) {
  //     //valid request to reset the password
  //     // setLoading(true);
  //     const resetPassword = async () => {
  //       try {
  //         const postData = new FormData();
  //         postData.append('email', formData.email);

  //         const options = {
  //           'credentials': 'include',
  //           'body': postData,
  //           'method': 'POST'
  //         }

  //         const response = await fetch(FORGOTPASSWORD_API, options);
  //         const jsonResponse = await response.json();
  //         console.log("RESPONSE I GOT AFTER RESETTING THE PASSWORD: " + jsonResponse);

  //         if (jsonResponse?.success === 1) {
  //           setValidReset(true);
  //         }
  //       } catch (err) {
  //         console.error("error while posting to server: ", err);
  //       }
  //     }
  //     setLoading(true);
  //     await resetPassword();
  //     setLoading(false);

  //     // after successful process and ack from server
  //     // display the message that the email has been sent.
  //     //console.log("am here");

  //   }
  // }, [emailError]);

  useEffect(() => {
    const resetPassword = async () => {
      console.log("emailError: " + formData.email + " Length: " + formData.email.length);
      if (Object.keys(emailError).length === 0 && isReset) {
        setLoading(true); // Set loading to true before the async operation
        try {
          const postData = new FormData();
          postData.append('email', formData.email);

          const options = {
            'credentials': 'include',
            'body': postData,
            'method': 'POST'
          }

          const response = await fetch(FORGOTPASSWORD_API, options);
          const jsonResponse = await response.json();
          console.log("RESPONSE I GOT AFTER RESETTING THE PASSWORD: " + jsonResponse);

          if (jsonResponse?.success === 1) {
            setValidReset(true);
          }
        } catch (err) {
          console.error("error while posting to server: ", err);
        } finally {
          setLoading(false); // Set loading to false after the async operation
        }
      }
    };

    resetPassword();
  }, [emailError, formData.email, isReset]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const validate = (data) => {
    const errors = {};
    if (data.email.length < 6) {
      errors.email = "E-mail should be minimum of 6 characters";
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailError(validate(formData));
    //handle the reset button press
    setIsReset(true);
    //validate the form data
  }

  return (
    <div className="h-4/5 w-full bg-customWhite rounded-lg p-3 md:p-5 flex items-center justify-center">

      {validReset ? <p className="form-success lg:text-lg">You will receive an email with instructions to reset your password shortly.</p> :

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="grid grid-rows-3 md:gap-4 ">
            <div className="row-span-2 w-full">
              {/* email input */}
              <input name="email" required className="h-2/3 p-2 w-full rounded-lg md:text-md lg:text-xl" type="email" placeholder="Enter your email to reset password" value={formData.email} onChange={handleChange} ></input>
              {/* error message */}
              <p className="h-1/3 form-error lg:text-base">{emailError.email}</p>
            </div>
            {/* button  */}
            <div className="justify-self-center row-span-1">
              <button disabled={loading} className="btn" type="submit">{loading ? 'Validating...' : 'Reset'} </button>
            </div>
          </div>
        </form>
      }

    </div>
  )
}

export default ForgotPassword;