import React from 'react';

const Disclaimer = () => {
  return (
    <div className="flex flex-col items-center gap-4 p-5">
      <h1 className="heading "> Cassandra Tool Disclaimer </h1>
      <div className="bg-gray-100 p-4 md:w-4/5 md:p-10  shadow-lg rounded-md">
      
        <p className="pb-5 lg:text-xl">
        This is a free service. Results may be missing recently discovered vulnerabilities not yet available from ICAT, and will 
        be missing vulnerabilities that have not been made public. Because the contents are derived from NIST's ICAT (and Secunia's) servers, 
        CERIAS cannot make any warranty. This service is a best effort delivery of the contents accessible by CERIAS. CERIAS shall not be liable for any missing information, 
        or for the consequences, direct or indirect, of emailing you vulnerability information, even if it is the result of a malfunction.
        </p>
      </div>
    </div>
  )
}

export default Disclaimer;