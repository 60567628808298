import React from 'react'
import Navbar from '../../Utils/Navbar';
import UserLogin from '../Authentication/UserLogin';
import Footer from '../../Utils/Footer';
import Modal from '../../Utils/Modal';
import ResetPasswordModal from './ResetPasswordModal';
import {useParams} from 'react-router-dom';

const ResetPassword = () => {
  const {token1,token2} = useParams();
  return (
    <>
    <div className="h-screen grid grid-rows-[1fr_6fr_2fr]">
    <Navbar/>
        <div className="grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 bg-customGray-light h-full">
      {/* cassandra text container */}
      <div className="flex flex-col items-center justify-evenly font-inter italic font-medium text-center">
        <div>
        <div class="h-2 bg-gradient-to-r from-transparent via-customBlack to-transparent m-4"></div>
        <span className="text-2xl lg:text-4xl">The Cassandra Tool</span>
        </div>
           <div className="flex items-center justify-center">
          <span className="whitespace-pre-wrap text-center">
                <p className="text-xl font-mono font-semibold lg:text-3xl" >The first tool to provide free,customized vulnerablity notifications</p>
          </span>
          </div>
        <div className="flex flex-col items-center text-2xl">
          <p>Create profiles to be notified</p>
          <p>about new CVEs</p>
        </div>
      </div>
      <div className="bg-customGray-light flex flex-col justify-center items-center ">
        <div className="h-4/5 w-4/5 lg:h-4/5 lg:w-4/5 rounded-lg">
        <UserLogin/>
        </div>
      </div>
    </div>
    <Footer />
    </div>
    <ResetPasswordModal token={token1} reset_nonce={token2}/>
    {/* <Modal headingText="Your Account Has Been Activated!" secondaryText="Please Login to continue..."/>  */}

    </>
  )
}

export default ResetPassword;