import React from 'react';
import { IoIosClose } from "react-icons/io";
import {Link} from "react-router-dom";

const ProfileModal = ({ profileId, isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center">
      <div className="bg-white w-4/5 grid grid-rows-5 rounded-lg md:text-lg lg:text-xl lg:w-2/5">
        <div className="row-span-1 flex justify-end p-2">
          <button onClick={onClose}>
            <IoIosClose className="text-5xl hover:text-red" />
          </button>
        </div>
        <div className="row-span-2 items-center justify-items-center">
        <hr className="h-1 bg-black/50 border-none" />
        <h1 className=" font-inter font-bold text-center uppercase p-4">Profile Added</h1>
        <h3 className="px-3 font-inter text-center font-semibold italic">
          Your new profile has been created. Now you can add keywords, products, or Vendors to this profile.
        </h3>
        </div>
        <div className="row-span-2 justify-self-center self-center flex gap-5">
          <Link className="bg-blue-500 text-white px-4 py-2 rounded" to={`${profileId}`} >Edit Profile</Link>
          <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default ProfileModal;
