import React from 'react';
import { ImPointRight } from 'react-icons/im';
import { MdDelete } from "react-icons/md";
import { MdChangeCircle } from "react-icons/md";
import {Link} from "react-router-dom";
const MyAccount = () => {
  return (

    <div className="h-full w-full p-10 flex flex-col gap-10 md:flex-row md:justify-center md:items-start">

        <Link to="/main/changepassword" className="flex cursor-pointer items-center justify-center btn gap-5 md:w-1/4">
           <MdChangeCircle className="text-2xl"/>
           <p>Change Password</p>
        </Link >

        <Link to="/main/deleteaccount" className="flex cursor-pointer items-center justify-center gap-5 btn md:w-1/4">
        <MdDelete className="text-2xl"/>
        <p>Delete Account</p>
        </Link>

    </div>
  )
}

export default MyAccount;