import React,{useState,useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';


const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const [pathnames, setPathnames] = useState([]);

  const fetchProfileName = async (id) => {
    try{
      const res = await fetch(`https://dev.cassandra.cerias.purdue.edu/api/profiles/view/${id}`,{credentials:'include'});
      const resJson = await res.json();
      return resJson;
      }
      catch(error){
          console.error(error);
      }
  };
  
  // Split the pathname into an array of paths
  useEffect(() => {
    const paths = pathname.split('/').filter((x) => x);

    const resolvePathnames = async () => {

      const resolvedPaths = await Promise.all(
        paths.map(async (value, index) => {
          // Check if the path is a number and might be an ID
          if (paths[index - 1] === 'profiles' && paths[index - 2] === 'main' && !isNaN(value)) {
            const  profileData  = await fetchProfileName(value);
            const profileName = profileData?.data?.profile_name || "profileName"
            const profileText = profileName.length > 20 ? profileName.substring(0,20)+"..." : profileName;
            return  profileText || value; // Replace ID with profile name or fallback to the original value
          }
          return value;
        })
      );
      setPathnames(resolvedPaths);
    };
    resolvePathnames();
  }, [pathname]);


  if(pathname==="/main") return null; // DONT DISPLAY IT ON MAIN PAGE

  return (
    <nav aria-label="breadcrumb" className="bg-customWhite-light rounded-md py-[5px]">
      <ol className="flex space-x-2">
        {/* <li className="inline-flex items-center">
          <Link to="/" className="text-blue-600 hover:underline">Home</Link>
        </li> */}
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return (
            <li key={to} className="inline-flex items-center">
              <span className="mx-2 text-gray-500"> {"/"} </span>
              {index < pathnames.length - 1 ? (
                <Link to={to} className="text-blue-600 font-semibold hover:underline">
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Link>
              ) : (
                <span className="text-gray-500">
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
