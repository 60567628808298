import React from 'react';

const Privacy = () => {
  return (
    <div className="flex flex-col items-center gap-4 p-5">
      <h1 className="heading "> Cassandra: Privacy </h1>
      <div className="bg-gray-100 p-4 shadow-lg rounded-md md:w-4/5 md:p-10 lg:text-xl ">
      <ul className="list-disc p-2">

      
        <li className="pb-4">
        CERIAS will not reveal your profiles to any third party (unless obliged to do so by law). 
        CERIAS employees in charge of maintaining this tool might see your profiles, although it should not ever happen. 
        By policy, no CERIAS employee is allowed to see profiles unless mandated by the owner of the profile, 
        or to solve a problem. We will, however, use statistical (aggregate), non-identifiable information from the profiles.
        </li>
        
        <li className="pb-4">
        All IP addresses that you use with this tool are logged, along with the time at which the cookie was granted.
        </li>

        <li>
        The queries that this tool makes for your sake are anonymous from the point of view of the NIST servers. 
        Because we use a local copy of the ICAT database, your profiles are never sent anywhere.
        </li>
        </ul>
      </div>
    </div>
  )
}

export default Privacy;