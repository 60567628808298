import React from 'react';
// import { ReactTyped } from 'react-typed';
import { Outlet } from 'react-router-dom';
import UserLogin from '../Pages/Authentication/UserLogin';


const WelcomeTile = () => {
  return (
    //main container
    <div className="grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 bg-customGray-light h-full">
      {/* cassandra text container */}
      <div className="flex flex-col items-center justify-evenly font-inter italic font-medium text-center">
        <div>
        <div class="h-2 bg-gradient-to-r from-transparent via-customBlack to-transparent m-4"></div>
        <span className="text-2xl lg:text-4xl">The Cassandra Tool</span>
        </div>

        {/* TYPING TEXT EFFECT */}
        {/* <div className="flex items-center justify-center">
          <span className="whitespace-pre-wrap text-center">
            <ReactTyped
              className="text-xl font-mono font-semibold lg:text-3xl"
              strings={[
                "The first tool to provide free,\ncustomized vulnerablity notifications",
                "Get Started Today"
              ]}
              typeSpeed={30}
              backSpeed={10}
              loop
            />
          </span>
          </div> */}

           <div className="flex items-center justify-center">
          <span className="whitespace-pre-wrap text-center">
                <p className="text-xl font-mono font-semibold lg:text-3xl" >The first tool to provide free,customized vulnerablity notifications</p>
          </span>
          </div>
          
        <div className="flex flex-col items-center text-2xl">
          <p>Create profiles to be notified</p>
          <p>about new CVEs</p>
        </div>
      </div>
      {/* login form container */}
      <div className="bg-customGray-light flex flex-col justify-center items-center ">
        <div className="h-4/5 w-4/5 lg:h-4/5 lg:w-4/5 rounded-lg">
        {/* h-5/6 w-5/6 lg:h-3/4 w-3/4 */}
        {/* <UserLogin/> */}
        <Outlet/>
        </div>
          
      </div>
    </div>

  )
}

export default WelcomeTile;