import React, { useRef, useState, useEffect } from 'react'
import { KEYWORD_ADD_API, KEYWORD_DELETE_API } from '../../../Utils/constants';
import useGetKeywords from '../../../Utils/Hooks/useGetKeywords';

const Keywords = ({ csrfToken, profileId }) => {

  const newKeyword = useRef();
  const formRef = useRef();
  const { keywordData, loading, error, fetchKeywords } = useGetKeywords(profileId);
  const [validAddKeyword, setValidAddKeyword] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isAddKeyword, setIsAddKeyword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const postData = async () => {
      if (Object.keys(formErrors).length === 0 && isAddKeyword) {
        //we got a valid signup form data to make a post request
        try {
          const postData = {
            CSRF: csrfToken,
            profile_id: profileId,
            keyword: newKeyword?.current?.value
          };
          const formData = new FormData();

          Object.keys(postData).forEach(key => {
            formData.append(key, postData[key]);
          });

          const options = {
            'credentials': 'include',
            'method': 'POST',
            'body': formData
          };
          // console.log(formData);
          for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
          }
          const response = await fetch(KEYWORD_ADD_API, options);
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          if (jsonResponse?.success === 1) {
            // on sucessful post
            setValidAddKeyword(true);
            //again make a fetch req to get the updated list
            await fetchKeywords();
            // Clear the form fields
            formRef?.current?.reset();
            setServerError(false);
          }
          else { setServerError(true) }
        }
        catch (err) {
          console.error("ERROR: " + err);
        }
      }
    }
    postData();

  }, [formErrors]);

  const validate = (values) => {
    let errors = {};
    //regex to check whether keyword only contains chars 
    const regex = /^[a-zA-Z0-9]+$/

    if (values.length < 3) {
      errors.err = "Minimum 3 characters expected";
    }
    else if (values.length > 20) {
      errors.err = "Minimum of 20 characters allowed";
    }

    else if (!regex.test(values)) {
      errors.err = "Only alphanumeric chars allowed";
      // "Password must contain at least one digit, one uppercase letter, and one lowercase letter";
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handling form submit");
    //CLIENT SIDE VALIDATION FOR FORM DATA.
    console.log(validate(newKeyword?.current?.value));
    setFormErrors(validate(newKeyword?.current?.value));
    //KEEP TRACK OF CREATE ACCOUNT BUTTON PRESS.
    setIsAddKeyword(true);
  }

  const handleKeywordDeletion = async (k_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Keyword?");
    if (confirmDelete) {
      try {
        const postData = {
          profile_id: profileId,
          CSRF: csrfToken,
          k_id: k_id
        }
        const formData = new FormData();
        Object.keys(postData).forEach((key) => formData.append(key, postData[key]));

        const options = {
          'method': 'POST',
          'credentials': 'include',
          'body': formData
        }
        const response = await fetch(KEYWORD_DELETE_API, options);
        const jsonResponse = await response.json();
        if (jsonResponse?.success === 1) {
          alert('Keyword deleted successfully.');
          //navigate('/profiles');
          //again fetch the keyword list
          await fetchKeywords();
        } else {
          alert('Failed to delete the keyword.');
        }
      } catch (error) {
        console.error('Error deleting keyword:', error);
        alert('An error occurred while deleting the keyword.');
      }
    }
  }


  if (loading) {
    //shimmer UI
    return <div>LOADING... -- SHIMMER-UI--</div>
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div className="p-2 h-auto text-center flex flex-col items-center justify-items-center">
  <h2 className=" font-inter text-xl font-semibold p-4 uppercase underline underline-offset-2 ">KEYWORDS</h2>
 
  <form
    ref={formRef}
    className=" col-span-5 grid grid-cols-6 grid-rows-1 w-full md:p-6 md:text-xl lg:p-0"
    onSubmit={handleSubmit}
  >
    <div className="col-span-full grid grid-cols-6 gap-2 mb-5">
      <input
        className="col-span-4  text-[20px] md:col-span-5 px-3 py-3 rounded-md font-semibold placeholder-gray-400 text-black border border-gray-500 focus:outline-black"
        placeholder="Add Keyword"
        type="text"
        ref={newKeyword}
      />
      <button type="submit" className="btn p-0 h-4/5 col-span-2 text-[20px] md:col-span-1 self-center">
        Add
      </button>
    </div>
    {Object.keys(formErrors).length !== 0 && (
      <p className="col-span-full form-error m-2 md:text-xl">{formErrors.err}</p>
    )}
    {serverError && (
      <p className="col-span-full form-error m-2 md:text-xl">Failed to add Keyword</p>
    )}
  </form>
  <div className="h-full w-full rounded-md  justify-items-center items-center md:p-6 lg:w-full lg:m-2 lg:p-0">
    <table className="h-full w-full  shadow-lg">
      <thead className="bg-gray-100">
        <tr>
          <th className="py-3 px-2 text-[20px] uppercase text-center">Keywords</th>
          <th className="py-3 px-2 text-[20px] uppercase text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {keywordData?.data?.length > 0 ? (
          keywordData?.data.map((keyword, index) => {
            const rowClass = index % 2 === 0 ? 'bg-white' : 'bg-gray-100';
            return (
              <tr className={`${rowClass}`} key={keyword?.k_id}>
                <td className="text-gray-600 text-[20px] px-2 py-2 text-center whitespace-nowrap">
                  <p className="font-semibold">{keyword?.keyword}</p>
                </td>
                <td className="text-center px-2 py-2 whitespace-nowrap">
                  <button
                    onClick={() => handleKeywordDeletion(keyword?.k_id)}
                    className="text-[17px] btn px-4 py-1 bg-gray-200 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="3" className=" bg-white py-3 text-center text-gray-500 font-semibold uppercase text-[20px]">
              Add a Keyword
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>
  )
}

export default Keywords;