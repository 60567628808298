import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:'user',
    initialState:{
        isLoggedIn:false
    },
    reducers:{
        logIn: (state,action) => {
            state.isLoggedIn = action.payload;
        },
        logOut:(state,action)=>{
            state.isLoggedIn = false;
        },
    }
});

export const {logIn,logOut} = userSlice.actions;

export default userSlice.reducer;