import React, { useState,useRef } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import {LOGIN_API} from '../../../Utils/constants';
import { useDispatch } from 'react-redux';
import {logIn} from '../../../Utils/Redux/Slices/userSlice';

const UserLogin = () => {
    const email = useRef("");
    const password = useRef("");
    const navigate = useNavigate();
    const [loginError,setLoginError] = useState(false);
    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();
    // action.payload = 1
    // use the logIn reducer from userSlice
     const postData = async () => {
            // console.log("am called");
        try {
            const postData = {
              email: email?.current?.value,
              pass1: password?.current?.value
            };
            console.log(postData);
            const formData = new FormData();
  
            Object.keys(postData).forEach(key => {
              formData.append(key, postData[key]);
            });
  
            const options = {
              'method': 'POST',
              'body': formData,
              'credentials':'include'
            };   
            const response = await fetch(LOGIN_API, options);
            const jsonResponse = await response.json();
            console.log(jsonResponse);
            if (jsonResponse.success === 1){
                //login successful => route to a different page
                //navigate()?
                //dispatch an action to update user slice -- letting the application know that the user is logged in.
                // dispatch(logIn(1));
                dispatch(logIn(true));
                navigate("/main/profiles");
            }
            else{
                // display stating check your email and password.
                // console.log("error");
                setLoginError(true);
            }
          }
          catch (err) {
            console.error("ERROR: " + err);
          }
        }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(loginData);
        // API CALL TO VALIDATE
        setLoading(true);
        await postData();
        setLoading(false); 
    }

    return (
        <>
            <form   className="h-full bg-customWhite rounded-lg p-3 md:p-5" onSubmit={handleSubmit}>
                <div className="grid grid-rows-7 grid-cols-2 h-full w-full gap-5 md:gap-0 ">
                    {/* USERNAME GRID */}
                    <div className="col-span-full row-span-2">
                        <input  ref={email} required  placeholder="Username" className="h-full w-full p-3 rounded-lg text-lg border border-black md:h-2/3 lg:text-xl"type="text"/>
                        {/* <p className="hidden h-1/3 font-semibold text-red-600 text-center">Incorrect username </p> */}
                    </div>

                    {/* PASSWORD GRID */}
                    <div className=" col-span-full row- row-span-2">
                        <input  ref={password} required name="password" type="password" placeholder="Password" className="h-full w-full p-3 text-lg rounded-md border border-black md:h-2/3 lg:text-xl"/>
                        {loginError && <p className="h-1/3 font-semibold text-red-600 text-center tracking-wide lg:text-lg">Incorrect username or password</p>} 
                    </div>

                    {/* LOGIN GRID*/}
                    <div className="justify-self-center self-center row-span-2">
                        <button disabled={loading}  className="btn text-[12px] lg:text-lg disabled:bg-gray-400 disabled:cursor-not-allowed" type="submit">{loading ? "Validating..." : "Login"}</button>
                    </div>

                    {/* FORGOT PASSWORD GRID */}
                    <div className="justify-self-center self-center row-span-2">
                        <Link className=" text-sm font-semibold  hover:text-slate-800 md:text-base lg:text-xl" to="forgotpassword"> Forgot Password ? </Link>
                    </div>

                    {/* SIGN UP */}
                    <div className="col-span-full justify-self-center self-center row-span-1 md:row-span-1">
                        <Link className=" text-md font-semibold underline underline-offset-4  hover:text-slate-800 md:text-base lg:text-xl" to="createaccount"> Create An Account </Link>
                    </div>

                </div>
            </form>

        </>
    )
}

export default UserLogin;