import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
const AutoComplete = ({ options, debouncedFetch, loading, placeholder, selectedOption, setSelectedOption, vendorOrProduct }) => {

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
    }
    return (
        <Autocomplete
            options={options}
            getOptionLabel={vendorOrProduct ? (option) => `${option.product_name} - (${option.vendor_name})` : (option) => option.vendor_name}
            onInputChange={(event, newInputValue) => {
                // setInputValue(newInputValue);
                debouncedFetch(newInputValue);
            }}
            value={selectedOption}
            onChange={handleChange}
            loading={loading}
            renderInput={(params) =>
                <TextField {...params}
                    placeholder={placeholder}
                    // className="col-span-4 text-[20px] px-2 py-2 rounded-md h-4/5"
                    InputLabelProps={{ shrink: false }}
                />}
            // className="col-span-4 text-[20px] md:col-span-5 px-2 py-2 rounded-md font-semibold h-4/5"
            className="placeholder-gray-500"
            // sx={{
            //     '& .MuiAutocomplete-listbox': {
            //         maxHeight: '200px', // Limit height of dropdown
            //         overflowY: 'auto',  // Enable scrolling
            //     },
            //     '& .MuiOutlinedInput-root': {
            //         backgroundColor:'white', // set background color to white
            //         '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            //             borderColor: 'black', // Change focus border color to black
            //         },
            //     },
            //     // '& .MuiInputBase-input': {
            //     //     color: 'black', // Change input text color to grey
            //     //   },
            //     '& .MuiInputLabel-root': {
            //         color: 'black', // Change label color
            //         '&.Mui-focused': {
            //             color: 'black', // Change label color when focused
            //         },
            //     },
            // }}
            sx={{
                '& .MuiAutocomplete-root': {
                    width: '100%', // Ensure the autocomplete takes full width
                    height: '100%',
                },
                '& .MuiAutocomplete-inputRoot': {
                    width: '100%', // Ensure the input root takes full width
                },
                '& .MuiAutocomplete-listbox': {
                    maxHeight: '200px', // Limit height of dropdown
                    overflowY: 'auto',  // Enable scrolling
                },
                '& .MuiOutlinedInput-root': {
                    width: '100%', // Ensure the outlined input takes full width
                    backgroundColor: 'white',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                    },
                },
                '& .MuiInputLabel-root': {
                    color: 'black',
                    '&.Mui-focused': {
                        color: 'black',
                    },
                },

                '& .MuiInputBase-input::placeholder': {
                    color: 'black',   // Placeholder color
                    fontSize: '1.2rem', // Increase placeholder font size
                    fontWeight: '700',  // Placeholder font weight
                },


            }}
        />
    )
}

export default AutoComplete;