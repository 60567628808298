import React,{useCallback} from 'react'
import {PROFILE_EMAILUPDATE_API} from '../constants';
import { RiContactsBookLine } from 'react-icons/ri';

const useUpdateEmail = () => {
    const UpdateEmail = useCallback(async({profileID,checked,csrfToken}) =>{
    try{
        const PostData = {
            profile_id: profileID,
            email_me: checked ? 1 : 0,
            CSRF: csrfToken
        }

        console.log(PostData);

        const formData = new FormData();

        Object.keys(PostData).forEach(key => {
            formData.append(key,PostData[key]);
        });

        const Response  = await fetch(PROFILE_EMAILUPDATE_API,{
            'method':'POST',
            'body':formData
        });
        const jsonResponse = await Response.json();
        console.log("am updating the email preference");
        console.log(jsonResponse?.success);
    }
    catch(error){
        console.error(error);
    }
},[]);
return UpdateEmail;
}

export default useUpdateEmail;