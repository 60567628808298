import React,{useContext} from 'react';
import { IoMenu } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { useState } from "react";
import img from "../../assets/the_cassandra_tool_logo.svg";
import { FaRegQuestionCircle, FaUser } from "react-icons/fa";
import { IoIosStats } from "react-icons/io";
import { FaRegFolderOpen } from "react-icons/fa6";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import clsx from "clsx";
import Logout from '../../Api/Logout';
import { useDispatch,useSelector } from 'react-redux';
import { MdNotes } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import BreadCrumb from './BreachCrumb';
import {AuthContext} from '../Auth/AuthContext';

const Navbar = () => {
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((store)=>store.user.isLoggedIn);

  // const { isAuthenticated } = useContext(AuthContext);


  const logOutSideBar = async () => {
    await Logout(dispatch);
    navigate("/main");
    setSideBarOpen(false);
  }

  return (
    <div className="flex flex-col">
    <div className="h-full bg-customBlack text-white grid grid-cols-2">
      {/* section 1 -- logo */}
      <section className="justify-self-center self-center pt-[10px] pb-[10px] pl-[30px] md:pl-0">
        {/* logo */}
        <h1>
        <Link to="/main" className="font-mono text-4xl">
          <img className="w-64 md:w-72 " src={img} alt="LOGO" />
        </Link>
        </h1>
        {/* {status==="true" ? <p>User Is LoggedIn</p> : <p>User Is Not Logged In</p> } */}
      </section>
      <nav className="h-full flex justify-between px-8 py-6 justify-self-end self-center md:justify-self-end navbarBreak:justify-self-center">
        {/* section 2 - navigations */}
        <section className="h-full w-full flex justify-evenly items-center md:justify-end">
          {/* menu icon */}
          <a>
          <div className="bg-customBrown  text-black cursor-pointer navbarBreak:hidden flex items-center rounded border border-customWhite px-2" onClick={() => (setSideBarOpen(true))}>
            <span className="font-mono font-medium text-lg m-2">MENU</span>
            <IoMenu className="text-4xl" />
          </div>
          </a>

          <div className="hidden navbarBreak:block font-mono font-bold px-8 text-xl  ">
            <div className="flex uppercase gap-10">
              <Link to="about">
                <div className="flex flex-col text-gray-300 hover:text-white justify-center items-center cursor-pointer">
                  <FaRegQuestionCircle className="" />
                  <p >About</p>
                </div>
              </Link>

              <Link to="stats">
                <div className="flex flex-col justify-center items-center text-gray-300 hover:text-white">
                  <IoIosStats className="" />
                  <p>Stats</p>
                </div>
              </Link>

              <Link to="cvelogs">
                <div className="flex flex-col text-gray-300 hover:text-white justify-center items-center">
                  <FaRegFolderOpen className="" />
                  <p>Logs</p>
                </div>
              </Link>

              <Link to="search">
                <div className="flex flex-col text-gray-300 hover:text-white justify-center items-center">
                  < IoSearch className="" />
                  <p>Search</p>
                </div>
              </Link>

              {/* ONLY IF USER IS LOGGED IN */}
              {/* USERICON --> IS A ACCORDIAN --> WITH DELETE ACCOUNT & CHANGE PASSWORD FUNCTIONALITY */}
              {/* LOGOUT BUTTON */}
              {status === true && <>
              {/* { isAuthenticated === true && <> */}

                <Link to="/main/profiles">
                <div className="flex flex-col justify-center items-center text-gray-300 hover:text-white">
                <MdNotes />
                  <p>Profiles</p>
                </div>
              </Link>
                
                <Link to="/main/myaccount">
                <div className="relative" onMouseEnter={() => setUserToggle(true)} onMouseLeave={() => setUserToggle(false)}>
                  <div className=" border border-white rounded-full p-2 cursor-pointer" >
                    <FaUser className="text-2xl" />
                  </div>
                  {userToggle &&
                    <div className="absolute flex flex-col bg-white text-black top-full left-1/2 transform -translate-x-1/2 inline-block w-max rounded-md p-2 shadow-lg text-center">
                      <div className="absolute top-[-2px] left-1/2 transform -translate-x-1/2 rotate-45 h-4 w-4 bg-white"></div>
                      <Link className="cursor-pointer text-sm m-1 hover:text-customOrange">Change Password</Link>
                      <Link className="cursor-pointer text-sm m-1 hover:text-customOrange">Delete Account</Link>
                    </div>}
                </div>
                </Link>
                  
                <button className="px-4 py-1 rounded-lg border border-white uppercase font-semibold text-white font-inter text-base text-center flex justify-center items-center hover:bg-white hover:text-black hover:border-black" onClick={() => logOutSideBar()}>Logout</button>
              </>}
            </div>
          </div>
        </section>

        {/* side-bar for mobiles */}
        <div className={clsx("fixed h-full w-screen navbarBreak:hidden bg-black/50 backdrop-blur-sm top-0 left-0 -translate-x-full transtion-all z-[200]", isSideBarOpen && "translate-x-0")}>
          <section className="w-64 text-white bg-customBlack flex flex-col absolute top-0 right-0 h-screen p-8 gap-8 z-[210]">
            <IoIosClose className="text-5xl cursor-pointer flex-shrink-0"
              onClick={() => (setSideBarOpen(false))}
            />

            <Link to="about" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>About</Link>
            <Link to="stats" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Stats</Link>
            <Link to="cvelogs" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Cve Logs</Link>
            <Link to="search" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Search</Link>
            {status === true &&
              <>
                <Link to="profiles" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Profiles</Link>
                <Link to="/main/changepassword" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Change Password</Link>
                <Link to="/main/deleteaccount" className="font-bold uppercase" onClick={() => (setSideBarOpen(false))}>Delete Account</Link>
                <button className="font-bold uppercase px-4 py-1 rounded-lg border border-white uppercase font-semibold text-white font-inter text-base text-center flex justify-center items-center" onClick={() => logOutSideBar()}>Logout</button>
              </>}
          </section>
        </div>
      </nav>
    </div>
    <BreadCrumb/>
    </div>
  )
}

export default Navbar;