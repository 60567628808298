import React, { useEffect,useState } from 'react';
import ReactDom from 'react-dom';
// import Home from './Pages/Home';
import Navbar from './Components/Utils/Navbar';
import Footer from './Components/Utils/Footer';
import About from './Components/Pages/About';
import Stats from './Components/Pages/Stats';
import CveLogs from './Components/Pages/CveLogs';
import Disclaimer from './Components/Pages/Disclaimer';
import Privacy from './Components/Pages/Privacy';
import Cookies from './Components/Pages/Cookies';
import PublicKey from './Components/Pages/PublicKey';
import Stats from './Components/Pages/Stats';
import UserLogin from './Components/Pages/Authentication/UserLogin';
import ForgotPassword from './Components/Pages/Authentication/ForgotPassword';
import CreateAccount from './Components/Pages/Authentication/CreateAccount';
import NoMatch from './Components/Pages/NoMatch';
import ConfirmSuccess from './Components/Pages/ConfirmSuccess';
import Profiles from './Components/Pages/Profiles/Profiles';
import ProfileSpecific from './Components/Pages/Profiles/ProfileSpecific';
import MyAccount from './Components/Utils/MyAccount';
import { createBrowserRouter,Navigate,Outlet,RouterProvider,useLocation, useNavigate } from 'react-router-dom';
import WelcomeTile from './Components/Utils/WelcomeTile';
import {STATS_API} from './Utils/constants';
import {Provider,useSelector} from 'react-redux';
import appStore from './Utils/Redux/appStore';
import useIsLoggedIn from './Utils/Hooks/useIsLoggedIn';
import Profiles from './Components/Pages/Profiles/Profiles';
import ResetPassword from './Components/Pages/Authentication/ResetPassword';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import Search from './Components/Pages/Search';
import { AuthProvider } from "./Components/Auth/AuthContext";
import {PUBLIC_KEY} from './Utils/constants';
// import {ProtectedRoute} from "./Components/Auth/ProtectedRoute";
// import {ISLOGGEDIN_API,TOKEN_API} from '../Utils/constants';
 
const loadStats = async() => {
    try{
        const statsData = await fetch(STATS_API);
        const statsJson = await statsData.json();
        return statsJson;
    }
    catch(err){
        console.error("ERROR CAUGHT: ", err);
    }
}

const AppLayout = () => {
    // const [loading,setLoading] = useState(true);

    // const navigate = useNavigate();
    // const location = useLocation();
    

    const loading = useIsLoggedIn(); // updates the userSlice
    if(loading){
        return null;
    }
    // console.log("LOADING LOADING :"+loading);

    // // ------------------------------------actual
    // const status = useSelector((store)=>store.user.isLoggedIn);
    // // -----------------------------------------
    // // dummy api
    // //----------------------------------------------------------------------------
    // //const status=true;
    // //----------------------------------------------------------------------------
    // const publicRoutes = [
    //     '/main', 
    //     '/main/forgotpassword', 
    //     '/main/createaccount', 
    //     '/main/about', 
    //     '/main/stats', 
    //     '/main/cvelogs', 
    //     '/main/disclaimer', 
    //     '/main/privacy', 
    //     '/main/cookies', 
    //     '/main/publickey'];
    // useEffect(()=>{
    //     // console.log("LOADING I GOT FROM HOOK:"+loading);
    //     // if(!loading){
    //     // setLoading(true);
    //     //const status = useSelector((store)=>store.user.isLoggedIn);
    //     console.log("STATUS: IS BEING CHANGED");

    //     if (status === false) {
    //         console.log("user is not loggedIn path: "+location.pathname);
    //         if (!publicRoutes.includes(location.pathname)) {
    //             console.log("am navigating this to /main");
    //             navigate("/main");
    //             // console.log(location.pathname);
    //             // console.log(" am here ");
    //             // navigate(location.pathname); // Redirect to main page if not logged in and accessing a protected route
    //         }
    //         else{
    //             console.log("user is not logged in and I am at a public route");
    //             console.log(`am navigating this to${location.pathname}`);
    //             navigate(location.pathname);
    //         }
    //     } 
    //     else{
    //         console.log("USER IS LOGGED IN");
    //         console.log(location.pathname);            
    //         if(location.pathname==='/main'){
    //             navigate('/main/profiles');
    //         }
    //          // Redirect to profiles page
    //     }
    // },[location.pathname,status,loading,navigate]);
    

    return(
    <div className="h-screen grid grid-rows-[1fr_6fr_2fr]">
        {/* <Navbar status={status}/> */}
        <Navbar/>
        <div className="bg-customGray-light">
        <Outlet />
        </div>
        <Footer />
    </div>
    );
}
// ROUTES AND Corresponding COMPONENTS

// AppLayout - has a outlet - at default it shows WelcomeTitle
// WelcomeTile - has a outlet - at default it shows UserLogin 
//  "/" - AppLayout -> WelcomeTile -> UserLogin
//  "/forgotpassword" - AppLayout -> WelcomeTile -> ForgotPassword
//  "/createaccount" - AppLayout -> WelcomeTile -> CreatePassword
//  "/main/about" - AppLayout -> About
//  "/main/stats" - AppLayout -> Stats
//  "/main/cvelogs" - AppLayout -> Cvelogs
//  "/main/disclaimer" - AppLayout -> Disclaimer
//  "/main/privacy" - AppLayout -> Privacy
//  "/main/cookies" - AppLayout -> Cookies
//  "/main/publickey" - AppLayout -> PublicKey
//  "/status/:id" - entire page(blurred) + modal(based on :id);
//  "/main/profiles" - AppLayout -> profiles page
//  "/main/profiles/:profile:id" --> specific profile page
//  "/for undefined routes" - AppLayout -> NoMatch

const appRouter = createBrowserRouter([
    {
        path:"/main",
        element:<AppLayout/>, 
        children:[
            {
                path:"/main/",
                element:<WelcomeTile/>,
                children:[
                    {
                        path:"/main/",
                        element:<UserLogin/>
                    },
                    {
                        path:"/main/forgotpassword",
                        element:<ForgotPassword/>
                    },
                    {
                        path:"/main/createaccount",
                        element:<CreateAccount/>
                    },
                ]
            },
            {
                path:"about",
                element:<About/>
            },
            {
                path:"myAccount",
                element:<MyAccount/>
            },
            {
                path:"stats",
                element:<Stats/>,
                loader:loadStats
            },
            {
                path:"cvelogs",
                element:<CveLogs/>
            },
            {
                path:"disclaimer",
                element:<Disclaimer/>
            },
            {
                path:"privacy",
                element:<Privacy/>
            },
            {
                path:"search",
                element:<Search/>
            },
            {
                path:"cookies",
                element:<Cookies/>
            },
            {
                path:"publickey",
                element:<PublicKey keyText={PUBLIC_KEY}/>
            },
            {
                path:"*",
                element:<NoMatch/>
            },
            {
                path:"profiles",
                element:(
                <ProtectedRoute>
                    <Profiles/>
                 </ProtectedRoute>
            )
            },
            {
                path:"profiles/:profileId",
                element:(
                <ProtectedRoute>
                   <ProfileSpecific/>
                </ProtectedRoute>
            )
            },
            
            
        ]
    },
    {
        path:"/status/:id",
        element:<ConfirmSuccess/>
    },
    {
        path:"/reset_password/:token1/:token2",
        element:<ResetPassword/>
    }
    
    // main/user/reset_password/LP55gRgep6E6ejO5QKHbZVJ0/pEVhKFQZdXqjFlvcQppGoME3UqsmhqKS

]);


const root = ReactDom.createRoot(document.getElementById("root"));
root.render(
//<React.StrictMode>
// <AuthProvider>
<Provider store={appStore}>

    <RouterProvider router={appRouter}/>

</Provider>
// </React.StrictMode>
);