import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NEWUSER_API } from '../../../Utils/constants';

const UserLogin = () => {
  const initialSignupData = { email: "", confirmpassword: "", password: "" };
  const [signUpData, setSignUpData] = useState(initialSignupData);
  const [isSignUp, setisSignUp] = useState(false);
  const [validSignup, setValidSignup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  // const [postData,setPostData] = useState("");

  useEffect(() => {
    const postData = async () => {
      if (Object.keys(formErrors).length === 0 && isSignUp) {
        //we got a valid signup form data to make a post request
        try {
          const postData = {
            email: signUpData.email,
            pass1: signUpData.password
          };
          const formData = new FormData();

          Object.keys(postData).forEach(key => {
            formData.append(key, postData[key]);
          });

          const options = {
            'method': 'POST',
            'body': formData
          };

          const response = await fetch(NEWUSER_API, options);
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          if (jsonResponse.success === 1) setValidSignup(true);
        }
        catch (err) {
          console.error("ERROR: " + err);
        }
      }
    }
    postData();
  }, [formErrors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value });
  }

  const validate = (values) => {
    const errors = {};
    //regex to check whether password has 1 uppercase character, 1 digit or 1 lowercase character
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;

    if (values.email.length < 6) {
      errors.email = "E-mail should be minimum of 6 characters"
    }
    if (values.password != values.confirmpassword) {
      errors.confirmpassword = "Passwords doesn't match";
    }
    if (values.password.length < 8) {
      errors.password = "Minimum of 8 characters required";
    }
    else if (values.password.length > 64) {
      errors.password = "Maximum of 64 characters allowed";
    }
    else if (!regex.test(values.password)) {
      errors.password = "Password Requirement not met";
      // "Password must contain at least one digit, one uppercase letter, and one lowercase letter";
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(signUpData);
    //CLIENT SIDE VALIDATION FOR FORM DATA.
    setFormErrors(validate(signUpData));
    //KEEP TRACK OF CREATE ACCOUNT BUTTON PRESS.
    setisSignUp(true);
  }

  return (
    <>
      <div className="h-full bg-customWhite rounded-lg p-4 md:p-5">
        {!validSignup ?
          <form className="h-full" onSubmit={handleSubmit}>
            <div className="grid grid-rows-9 grid-cols-2 h-full w-full gap-2 md:gap-0 ">

              {/* USERNAME GRID */}
              <div className="col-span-full row-span-2">
                <input required name="email" placeholder="E-mail" className="h-2/3 w-full p-3 rounded-lg text-lg border border-black lg:text-xl" type="email" value={signUpData.email} onChange={handleChange} />
                <p className="h-1/3 form-error">{formErrors.email}</p>
              </div>

              {/* PASSWORD GUIDANCE GRID */}
              <div className="col-span-full row-span-1">
                <p className="h-1/3 text-[11px] text-center font-medium font-inter lg:text-sm">Password must be at least 8 characters & include a digit,uppercase,and a lowercase letter.</p>
              </div>

              {/* PASSWORD GRID */}
              <div className=" col-span-full row-span-2">
                <input required name="password" type="password" placeholder="Password" className="h-2/3 w-full p-3 text-lg rounded-md border border-black" value={signUpData.password} onChange={handleChange} />
                <p className="h-1/3 form-error">{formErrors.password}</p>
              </div>

              {/* CONFIRM PASSWORD GRID */}
              <div className=" col-span-full row-span-2">
                <input required name="confirmpassword" type="password" placeholder="Confirm Password" className="h-2/3 w-full p-3 text-lg rounded-md border border-black" value={signUpData.confirmpassword} onChange={handleChange} />
                <p className="h-1/3 form-error">{formErrors.confirmpassword}</p>
              </div>

              {/* SIGN UP GRID*/}
              <div className="col-span-full row-span-1 justify-self-center self-center row-span-1">
                <button className="btn" type="submit"> SignUp </button>
              </div>

              {/* ALREADY HAVE AN ACCOUNT? LOGIN GRID */}
              <div className="col-span-full row-span-1 justify-self-center self-end">
                <Link className=" text-sm font-semibold  hover:text-slate-800 md:text-base lg:text-xl" to="/main"> Already have an Account?<span className="underline underline-offset-2"> Login </span> </Link>
              </div>
            </div>
          </form>
          :
          <div className="h-full flex justify-center items-center">
            <p className="form-success text-center md:text-xl"> A link to activate your account has been emailed to the address provided </p>
          </div>
        }
      </div>
    </>
  )
}

export default UserLogin;