import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { PROFILE_API } from '../constants';


const useGetProfileData = () => {
    const [profileData,setProfileData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);
    // const userCookie = useSelector((store)=>store.csrf.token);

    const fetchProfiles = async() => {
        try{
        const res = await fetch(PROFILE_API,{credentials:'include'});
        const resJson = await res.json();
        console.log(resJson);
        setProfileData(resJson);
        }
        catch(error){
            console.error(error);
            setError(error);
        }
        finally{
            setLoading(false);
        }
        
    }

    useEffect(()=>{ 
            fetchProfiles();
    },[]);
    
    //console.log(userCookie);
    // const response = 
  
  return {profileData,loading,error,fetchProfiles};
}

export default useGetProfileData;