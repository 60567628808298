import {createSlice} from "@reduxjs/toolkit";

const csrfSlice = createSlice({
    name:'csrf',
    initialState:{
        token:""
    },
    reducers:{
        setToken:(state,action) => {
            state.token = action.payload;
        },
        resetToken:(state,action) => {
            state.token = "";
        }
    },
});

export const {setToken,resetToken} = csrfSlice.actions;
export default csrfSlice.reducer;
