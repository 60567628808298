import { useEffect,useState } from 'react';

const useGetVendors = (profileId) => {
    const [vendorData,setVendorData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    console.log(profileId);

    const fetchVendors = async() => {
        try{
        const res = await fetch(`https://dev.cassandra.cerias.purdue.edu/api/profiles/vendors/${profileId}`,{credentials:'include'});
        const resJson = await res.json();
        console.log(resJson);
        setVendorData(resJson?.data);
        }
        catch(error){
            console.error(error);
            setError(error);
        }
        finally{
            setLoading(false);
        }
    }
    useEffect(()=>{ 
        fetchVendors();
    },[]);
    //console.log(userCookie);
    // const response = 
  return {vendorData,loading,error,fetchVendors};
}

export default useGetVendors;