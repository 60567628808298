import { useEffect,useState } from 'react';

const useGetProfileSpecificData = (profileId) => {
    const [profileData,setProfileData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    console.log(profileId);

    const fetchProfile = async() => {
        try{
        const res = await fetch(`https://dev.cassandra.cerias.purdue.edu/api/profiles/view/${profileId}`,{credentials:'include'});
        const resJson = await res.json();
        console.log(resJson);
        setProfileData(resJson);
        }
        catch(error){
            console.error(error);
            setError(error);
        }
        finally{
            setLoading(false);
        }
    }
    useEffect(()=>{ 
            fetchProfile();
    },[]);
    
    //console.log(userCookie);
    // const response = 
  return {profileData,loading,error,fetchProfile};
}

export default useGetProfileSpecificData;