import { useEffect,useState } from 'react';

const useGetKeywords = (profileId) => {
    const [keywordData,setKeywordData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    console.log(profileId);

    const fetchKeywords = async() => {
        try{
        const res = await fetch(`https://dev.cassandra.cerias.purdue.edu/api/profiles/keywords/${profileId}`,{credentials:'include'});
        const resJson = await res.json();
        console.log(resJson);
        setKeywordData(resJson);
        }
        catch(error){
            console.error(error);
            setError(error);
        }
        finally{
            setLoading(false);
        }
    }
    useEffect(()=>{ 
        fetchKeywords();
    },[]);
    
    //console.log(userCookie);
    // const response = 
  return {keywordData,loading,error,fetchKeywords};
}

export default useGetKeywords;