import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn); // Assuming Redux is used to manage login state

  if (!isLoggedIn) {
    return <Navigate to="/main/" replace />;
  }

  return children;
};

export default ProtectedRoute;